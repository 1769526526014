<div #sidenav class="sidenav" [@slideInOut]="menuState$ | async">
  <ul class="sidenav__list">
    @for (button of menuItems; track $index) {
      @if (button.showOnMobile) {
        <li class="sidenav__item" (click)="navButtonAction(button)">
          {{ button.name }}
        </li>
      }
    }
  </ul>
</div>
