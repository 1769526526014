import { ViewportScroller } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, RouterOutlet, Scroll } from '@angular/router';
import { FooterComponent } from '@layout/footer';
import { HeaderComponent } from '@layout/header';
import { SidenavComponent } from '@layout/sidenav';
import { IconsService, LoaderService } from '@services';
import { LoaderComponent } from '@ui/loader';
import { filter } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterOutlet,
    LoaderComponent,
    SidenavComponent,
    HeaderComponent,
    FooterComponent,
  ],
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(
    private router: Router,
    public loaderService: LoaderService,
    private viewportScroller: ViewportScroller,
    private iconsService: IconsService,
  ) {
    this.router.events
      .pipe(
        filter((event): event is Scroll => event instanceof Scroll),
        takeUntilDestroyed(),
      )
      .subscribe((event) => {
        setTimeout(() => {
          if (event.position) {
            this.viewportScroller.scrollToPosition(event.position);
          } else if (event.anchor) {
            this.viewportScroller.scrollToAnchor(event.anchor);
          } else {
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        });
      });
    // инициализация всех иконок приложения
    this.iconsService.initIcons();
  }
}
