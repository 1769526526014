@if (isLoading$ | async) {
  <div class="overlay">
    <mat-progress-spinner
      class="spinner"
      [color]="'primary'"
      [mode]="'indeterminate'"
      [value]="50"
    />
  </div>
}
