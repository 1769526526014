import { AsyncPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { menuItems } from '@configs';
import { MenuItem } from '@models';
import { MenuService, ToggleSidenavService } from '@services';
import { BehaviorSubject } from 'rxjs';

import { sidenavAnimation } from './sidenav.animation';

@Component({
  animations: [sidenavAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, AsyncPipe],
  selector: 'app-sidenav',
  standalone: true,
  styleUrls: ['./sidenav.component.scss'],
  templateUrl: './sidenav.component.html',
})
export class SidenavComponent implements OnInit {
  private readonly menuStateSubject$ = new BehaviorSubject<string>('out');

  menuItems = menuItems;

  readonly menuState$ = this.menuStateSubject$.asObservable();

  @ViewChild('sidenav', { static: false }) sidenav!: ElementRef<HTMLElement>;

  constructor(
    private router: Router,
    private sidenavService: ToggleSidenavService,
    // private scrollLockService: NgBodyScrollLockService,
    private menuService: MenuService,
    private destroyRef: DestroyRef,
  ) {}

  menuIn(): void {
    this.menuState = 'in';
    // this.scrollLockService.DisableBodyScroll(this.sidenav.nativeElement);
  }

  menuOut(): void {
    this.menuState = 'out';
    // this.scrollLockService.EnableBodyScroll(this.sidenav.nativeElement);
  }

  navButtonAction(button: MenuItem): void {
    setTimeout(() => {
      this.menuOut();
    }, 1000);
    this.menuService.navButtonAction(button);
  }

  ngOnInit(): void {
    this.sidenavService.toggleSidebar
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.toggleMenu());

    this.router.events
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.menuState = 'out';
        // this.scrollLockService.EnableBodyScroll(this.sidenav.nativeElement);
      });
  }

  toggleMenu(): void {
    this.menuState === 'out' ? this.menuIn() : this.menuOut();
  }

  set menuState(state: string) {
    this.menuStateSubject$.next(state);
  }

  get menuState(): string {
    return this.menuStateSubject$.getValue();
  }
}
