import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config.browser';
import { AppComponent } from './app/app.component';
import * as SentryBrowser from '@sentry/angular';
import { environment } from '@environment';

SentryBrowser.init({
  dsn: 'https://adbe7f76117e4186b4372bab122596ba@sentry.pir.ru/5',
  environment: environment.production ? 'production' : 'development',
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    SentryBrowser.browserTracingIntegration(),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    SentryBrowser.replayIntegration(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
  tracesSampleRate: 1.0,
  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: ['localhost'],
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
