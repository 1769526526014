import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    loadComponent: () => import('@pages/main').then((m) => m.MainComponent),
    path: '',
  },
  {
    loadComponent: () =>
      import('@pages/program').then((m) => m.ProgramComponent),
    path: 'program',
  },
  {
    loadComponent: () => import('@pages/expert').then((m) => m.ExpertComponent),
    path: 'expert/:id',
  },
  {
    loadComponent: () =>
      import('@pages/photo-gallery').then((m) => m.PhotoGalleryComponent),
    path: 'gallery',
  },
  {
    loadComponent: () =>
      import('@pages/experts-all').then((m) => m.ExpertsAllComponent),
    path: 'experts',
  },
  {
    loadComponent: () => import('@pages/news').then((m) => m.NewsComponent),
    path: 'news',
  },
  {
    loadComponent: () => import('@pages/get-ticket').then(c => c.GetTicketComponent),
    path: 'get-ticket',
  },
  {
    loadComponent: () =>
      import('@pages/not-found').then((m) => m.NotFoundComponent),
    path: '**',
  },
];
