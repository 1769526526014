import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { TokenStorageService } from '@services';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  awardApi = environment.award_api;

  constructor(private tokenStorage: TokenStorageService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this.tokenStorage.getToken() && request.url.includes(this.awardApi)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Token ${this.tokenStorage.getToken()}`,
        },
      });
    }
    return next.handle(request);
  }
}
