import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoaderService } from '@services';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private currentRequests: number;

  constructor(public loaderService: LoaderService) {
    this.currentRequests = 0;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    this.currentRequests++;
    if (this.currentRequests === 1) {
      this.loaderService.show();
    }
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.currentRequests--;
            if (this.currentRequests === 0) {
              this.loaderService.hide();
            }
          }
        },
        () => {
          this.currentRequests = 0;
          this.loaderService.hide();
        },
      ),
    );
  }
}
