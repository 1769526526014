import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
} from '@angular/common/http';
import { BESTCHEFS_SERVICE_STORAGE } from './services/session-storage.service';
import { SESSION_STORAGE } from 'ngx-webstorage-service';
import { LoaderInterceptor } from './http-interceptors/loader.interceptor';
import { GalleryModule } from '@ks89/angular-modal-gallery';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
    ),
    provideHttpClient(withFetch()),
    provideClientHydration(),
    provideAnimationsAsync(),
    importProvidersFrom(GalleryModule),
    { multi: true, provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor },
    { provide: BESTCHEFS_SERVICE_STORAGE, useExisting: SESSION_STORAGE },
  ],
};
