import {
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { inject, InjectionToken } from '@angular/core';

export const HTTP_CACHE_STORAGE = new InjectionToken<
  Map<string, HttpResponse<any>>
>('HTTP_CACHE_STORAGE', {
  providedIn: 'root',
  factory: () => new Map<string, HttpResponse<any>>(),
});

export function httpCacheInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const cacheStorage = inject(HTTP_CACHE_STORAGE);

  if (req.method !== 'GET') return next(req);

  const cachedResponse = cacheStorage.get(req.urlWithParams);

  if (cachedResponse) return of(cachedResponse.clone());

  return next(req).pipe(
    tap((event) => {
      if (event instanceof HttpResponse) {
        cacheStorage.set(req.urlWithParams, event.clone());
      }
    }),
  );
}
