import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { LoaderService } from '@services';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatProgressSpinner, AsyncPipe],
  selector: 'app-loader',
  standalone: true,
  styleUrls: ['./loader.component.scss'],
  templateUrl: './loader.component.html',
})
export class LoaderComponent {
  readonly isLoading$ = this.loaderService.isLoading;

  constructor(private loaderService: LoaderService) {}
}
